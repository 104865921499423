export interface City {
  code: string;
  name: string;
}

/* eslint-disable id-length */
/**
 * Cities mapped to country
 */
export const CITIES_BY_COUNTRY: Record<string, City[] | undefined> = {
  AE: [
    { code: 'Abu Dhabi', name: 'Abu Dhabi' },
    { code: 'Ajman', name: 'Ajman' },
    { code: 'Al Ain', name: 'Al Ain' },
    { code: 'Dubai', name: 'Dubai' },
    { code: 'Fujairah', name: 'Fujairah' },
    { code: 'Ras Al Khaimah', name: 'Ras Al Khaimah' },
    { code: 'Sharjah', name: 'Sharjah' },
    { code: 'Umm Al Quwain', name: 'Umm Al Quwain' },
  ],
  QA: [
    { code: 'Al Khor', name: 'Al Khor' },
    { code: 'Al Rayyan', name: 'Al Rayyan' },
    { code: 'Al Shamal', name: 'Al Shamal' },
    { code: 'Doha', name: 'Doha' },
  ],
  SA: [
    { code: 'Abha', name: 'Abha' },
    { code: 'Abqaiq', name: 'Abqaiq' },
    { code: 'Abu Arish', name: 'Abu Arish' },
    { code: 'Abu Hadriyah', name: 'Abu Hadriyah' },
    { code: 'Afif', name: 'Afif' },
    { code: 'Aflaj', name: 'Aflaj' },
    { code: 'Ahad Masarah', name: 'Ahad Masarah' },
    { code: 'Ahad Rofaidah', name: 'Ahad Rofaidah' },
    { code: 'Ain Dar', name: 'Ain Dar' },
    { code: 'Ajfar', name: 'Ajfar' },
    { code: 'Ajyad', name: 'Ajyad' },
    { code: 'Al Anbariya', name: 'Al Anbariya' },
    { code: 'Al Arisa', name: 'Al Arisa' },
    { code: 'Al Asiyah', name: 'Al Asiyah' },
    { code: 'Al Atawilah', name: 'Al Atawilah' },
    { code: 'Al Awaly', name: 'Al Awaly' },
    { code: 'Al Ayss', name: 'Al Ayss' },
    { code: 'Al Baha', name: 'Al Baha' },
    { code: 'Al Bahar', name: 'Al Bahar' },
    { code: 'Al Bashair', name: 'Al Bashair' },
    { code: 'Al Busaita', name: 'Al Busaita' },
    { code: 'Al Gazaz Al Balad', name: 'Al Gazaz Al Balad' },
    { code: 'Al Gewaizah', name: 'Al Gewaizah' },
    { code: 'Al Ghat', name: 'Al Ghat' },
    { code: 'Al Ghazah', name: 'Al Ghazah' },
    { code: 'Al Ghazalah', name: 'Al Ghazalah' },
    { code: 'Al Goz', name: 'Al Goz' },
    { code: 'Al Haet', name: 'Al Haet' },
    { code: 'Al Hajoun', name: 'Al Hajoun' },
    { code: 'Al Hareeq', name: 'Al Hareeq' },
    { code: 'Al Harjah', name: 'Al Harjah' },
    { code: 'Al Hasa', name: 'Al Hasa' },
    { code: 'Al Hurra Al Sharqya', name: 'Al Hurra Al Sharqya' },
    { code: 'Al Jouf', name: 'Al Jouf' },
    { code: 'Al Juhaima', name: 'Al Juhaima' },
    { code: 'Al Jumum', name: 'Al Jumum' },
    { code: 'Al Kharj', name: 'Al Kharj' },
    { code: 'Al Khobar', name: 'Al Khobar' },
    { code: 'Al Lith', name: 'Al Lith' },
    { code: 'Al Lith Town', name: 'Al Lith Town' },
    { code: 'Al Mahd', name: 'Al Mahd' },
    { code: 'Al Mandaq', name: 'Al Mandaq' },
    { code: 'Al Masfalah', name: 'Al Masfalah' },
    { code: 'Al Mawya', name: 'Al Mawya' },
    { code: 'Al Mikhwa', name: 'Al Mikhwa' },
    { code: 'Al Moyan', name: 'Al Moyan' },
    { code: 'Al Mubarraz', name: 'Al Mubarraz' },
    { code: 'Al Mutheilief', name: 'Al Mutheilief' },
    { code: 'Al Nabhanya', name: 'Al Nabhanya' },
    { code: 'Al Namass', name: 'Al Namass' },
    { code: 'Al Ola', name: 'Al Ola' },
    { code: 'Al Otaibya', name: 'Al Otaibya' },
    { code: 'Al Oyoun', name: 'Al Oyoun' },
    { code: 'Al Qasab', name: 'Al Qasab' },
    { code: 'Al Qassim', name: 'Al Qassim' },
    { code: 'Al Quaara', name: 'Al Quaara' },
    { code: 'Al Rafaye', name: 'Al Rafaye' },
    { code: 'Al Rain', name: 'Al Rain' },
    { code: 'Al Rass', name: 'Al Rass' },
    { code: 'Al Sahna', name: 'Al Sahna' },
    { code: 'Al Sayel Al Akbar', name: 'Al Sayel Al Akbar' },
    { code: 'Al Shanan', name: 'Al Shanan' },
    { code: 'Al Shuhada', name: 'Al Shuhada' },
    { code: 'Al Suheimi', name: 'Al Suheimi' },
    { code: 'Al Suiten Al Hawya', name: 'Al Suiten Al Hawya' },
    { code: 'Al Wadyayn', name: 'Al Wadyayn' },
    { code: 'Al Wajh', name: 'Al Wajh' },
    { code: 'Al Yatma', name: 'Al Yatma' },
    { code: 'Al Zaher', name: 'Al Zaher' },
    { code: 'Anaq', name: 'Anaq' },
    { code: 'Aqeeq', name: 'Aqeeq' },
    { code: 'Aradah', name: 'Aradah' },
    { code: 'Arar', name: 'Arar' },
    { code: 'Artawiah', name: 'Artawiah' },
    { code: 'Ayoon Al Jawa', name: 'Ayoon Al Jawa' },
    { code: 'Badayah', name: 'Badayah' },
    { code: 'Badr', name: 'Badr' },
    { code: 'Baeish', name: 'Baeish' },
    { code: 'Bagadia', name: 'Bagadia' },
    { code: 'Baha', name: 'Baha' },
    { code: 'Bahra', name: 'Bahra' },
    { code: 'Baljurshi', name: 'Baljurshi' },
    { code: 'Ballasmar', name: 'Ballasmar' },
    { code: 'Bani Ammr', name: 'Bani Ammr' },
    { code: 'Baqaa', name: 'Baqaa' },
    { code: 'Bariq', name: 'Bariq' },
    { code: 'Batha Border', name: 'Batha Border' },
    { code: 'Beish', name: 'Beish' },
    { code: 'Bisha', name: 'Bisha' },
    { code: 'Brazan', name: 'Brazan' },
    { code: 'Bukayria', name: 'Bukayria' },
    { code: 'Buraidah', name: 'Buraidah' },
    { code: 'Dalemiya', name: 'Dalemiya' },
    { code: 'Damad', name: 'Damad' },
    { code: 'Dammam', name: 'Dammam' },
    { code: 'Darb', name: 'Darb' },
    { code: 'Dawadmi', name: 'Dawadmi' },
    { code: 'Dawmat Al Jandal', name: 'Dawmat Al Jandal' },
    { code: 'Dhabab', name: 'Dhabab' },
    { code: 'Dhahran', name: 'Dhahran' },
    { code: 'Dhahran Al Janoub', name: 'Dhahran Al Janoub' },
    { code: 'Dhobbat', name: 'Dhobbat' },
    { code: 'Dhukna', name: 'Dhukna' },
    { code: 'Dhulam', name: 'Dhulam' },
    { code: 'Dilam', name: 'Dilam' },
    { code: 'Duba', name: 'Duba' },
    { code: 'Durma', name: 'Durma' },
    { code: 'Durya', name: 'Durya' },
    { code: 'Eskan', name: 'Eskan' },
    { code: 'Fawara', name: 'Fawara' },
    { code: 'Gassim', name: 'Gassim' },
    { code: 'Gella', name: 'Gella' },
    { code: 'Gizan', name: 'Gizan' },
    { code: 'Gurayat', name: 'Gurayat' },
    { code: 'Habuna', name: 'Habuna' },
    { code: 'Hada', name: 'Hada' },
    { code: 'Hafar Al Batin', name: 'Hafar Al Batin' },
    { code: 'Hail', name: 'Hail' },
    { code: 'Halat Ammar', name: 'Halat Ammar' },
    { code: 'Hanakiyah', name: 'Hanakiyah' },
    { code: 'Haql', name: 'Haql' },
    { code: 'Haradh', name: 'Haradh' },
    { code: 'Hawdat Sudair', name: 'Hawdat Sudair' },
    { code: 'Hawdat Tamim', name: 'Hawdat Tamim' },
    { code: 'Hayet', name: 'Hayet' },
    { code: 'Hayir', name: 'Hayir' },
    { code: 'Helban', name: 'Helban' },
    { code: 'Hijaz', name: 'Hijaz' },
    { code: 'Hijrat Laban', name: 'Hijrat Laban' },
    { code: 'Hiteem', name: 'Hiteem' },
    { code: 'Hofuf', name: 'Hofuf' },
    { code: 'Hota Bin Tamim', name: 'Hota Bin Tamim' },
    { code: 'Hota Sudhair', name: 'Hota Sudhair' },
    { code: 'Huraimala', name: 'Huraimala' },
    { code: 'Imam University', name: 'Imam University' },
    { code: 'Jabal Al Noor', name: 'Jabal Al Noor' },
    { code: 'Jafar', name: 'Jafar' },
    { code: 'Jalajil', name: 'Jalajil' },
    { code: 'Jamjoom', name: 'Jamjoom' },
    { code: 'Jarda', name: 'Jarda' },
    { code: 'Jareer', name: 'Jareer' },
    { code: 'Jeddah', name: 'Jeddah' },
    { code: 'Jubail', name: 'Jubail' },
    { code: 'Jubbah', name: 'Jubbah' },
    { code: 'Juhaimia', name: 'Juhaimia' },
    { code: 'Kafa', name: 'Kafa' },
    { code: 'Khabra', name: 'Khabra' },
    { code: 'Khafji', name: 'Khafji' },
    { code: 'Khaibar', name: 'Khaibar' },
    { code: 'Khalidya', name: 'Khalidya' },
    { code: 'Khamis Mushayat', name: 'Khamis Mushayat' },
    { code: 'Khazzan', name: 'Khazzan' },
    { code: 'Khubaib', name: 'Khubaib' },
    { code: 'Khurais', name: 'Khurais' },
    { code: 'Khurma', name: 'Khurma' },
    { code: 'Khutta', name: 'Khutta' },
    { code: 'Muhayil Aseer', name: 'Muhayil Aseer' },
    { code: 'Majardeh', name: 'Majardeh' },
    { code: 'Majmaah', name: 'Majmaah' },
    { code: 'Makkah', name: 'Makkah' },
    { code: 'Mandaq', name: 'Mandaq' },
    { code: 'Manfouah', name: 'Manfouah' },
    { code: 'Manifa', name: 'Manifa' },
    { code: 'Mather', name: 'Mather' },
    { code: 'Mawqeq', name: 'Mawqeq' },
    { code: 'Mazrueya', name: 'Mazrueya' },
    { code: 'Medina', name: 'Medina' },
    { code: 'Megaibrah', name: 'Megaibrah' },
    { code: 'Midhnab', name: 'Midhnab' },
    { code: 'Mogharazat', name: 'Mogharazat' },
    { code: 'Mubarraq', name: 'Mubarraq' },
    { code: 'Mutamarat', name: 'Mutamarat' },
    { code: 'Nabaniya', name: 'Nabaniya' },
    { code: 'Nafa', name: 'Nafa' },
    { code: 'Naim', name: 'Naim' },
    { code: 'Najran', name: 'Najran' },
    { code: 'Nasriya', name: 'Nasriya' },
    { code: 'Oqlah Al Sugur', name: 'Oqlah Al Sugur' },
    { code: 'Riyadh', name: 'Riyadh' },
    { code: 'Yanbu', name: 'Yanbu' },
  ],
};
/* eslint-enable id-length */
