export interface Province {
  code: string;
  name: string;
}

/* eslint-disable id-length */
/**
 * Provinces/States mapped to country
 */
export const PROVINCES_BY_COUNTRY: Record<string, Province[] | undefined> = {
  AU: [
    { code: 'NSW', name: 'New South Wales' },
    { code: 'QLD', name: 'Queensland' },
    { code: 'SA', name: 'South Australia' },
    { code: 'TAS', name: 'Tasmania' },
    { code: 'VIC', name: 'Victoria' },
    { code: 'WA', name: 'Western Australia' },
    { code: 'ACT', name: 'Australian Capital Territory' },
    { code: 'NT', name: 'Northern Territory' },
  ],
  CA: [
    { code: 'AB', name: 'Alberta' },
    { code: 'BC', name: 'British Columbia' },
    { code: 'MB', name: 'Manitoba' },
    { code: 'NB', name: 'New Brunswick' },
    { code: 'NL', name: 'Newfoundland and Labrador' },
    { code: 'NS', name: 'Nova Scotia' },
    { code: 'NU', name: 'Nunavut' },
    { code: 'NT', name: 'Northwest Territories' },
    { code: 'ON', name: 'Ontario' },
    { code: 'PE', name: 'Prince Edward Island' },
    { code: 'QC', name: 'Quebec' },
    { code: 'SK', name: 'Saskatchewan' },
    { code: 'YT', name: 'Yukon' },
  ],
  IN: [
    { code: 'AN', name: 'Andaman and Nicobar Islands' },
    { code: 'AP', name: 'Andhra Pradesh' },
    { code: 'AR', name: 'Arunachal Pradesh' },
    { code: 'AS', name: 'Assam' },
    { code: 'BR', name: 'Bihar' },
    { code: 'CH', name: 'Chandigarh' },
    { code: 'CT', name: 'Chhattisgarh' },
    { code: 'DN', name: 'Dadra and Nagar Haveli' },
    { code: 'DD', name: 'Daman and Diu' },
    { code: 'DL', name: 'Delhi' },
    { code: 'GA', name: 'Goa' },
    { code: 'GJ', name: 'Gujarat' },
    { code: 'HR', name: 'Haryana' },
    { code: 'HP', name: 'Himachal Pradesh' },
    { code: 'JK', name: 'Jammu and Kashmir' },
    { code: 'JH', name: 'Jharkhand' },
    { code: 'KA', name: 'Karnataka' },
    { code: 'KL', name: 'Kerala' },
    { code: 'LA', name: 'Ladakh' },
    { code: 'LD', name: 'Lakshadweep' },
    { code: 'MP', name: 'Madhya Pradesh' },
    { code: 'MH', name: 'Maharashtra' },
    { code: 'MN', name: 'Manipur' },
    { code: 'ML', name: 'Meghalaya' },
    { code: 'MZ', name: 'Mizoram' },
    { code: 'NL', name: 'Nagaland' },
    { code: 'OR', name: 'Odisha' },
    { code: 'PY', name: 'Puducherry' },
    { code: 'PB', name: 'Punjab' },
    { code: 'RJ', name: 'Rajasthan' },
    { code: 'SK', name: 'Sikkim' },
    { code: 'TN', name: 'Tamil Nadu' },
    { code: 'TG', name: 'Telangana' },
    { code: 'TR', name: 'Tripura' },
    { code: 'UP', name: 'Uttar Pradesh' },
    { code: 'UT', name: 'Uttarakhand' },
    { code: 'WB', name: 'West Bengal' },
  ],
  JP: [
    { code: '23', name: 'Aichi' },
    { code: '05', name: 'Akita' },
    { code: '02', name: 'Aomori' },
    { code: '12', name: 'Chiba' },
    { code: '38', name: 'Ehime' },
    { code: '18', name: 'Fukui' },
    { code: '40', name: 'Fukuoka' },
    { code: '07', name: 'Fukushima' },
    { code: '21', name: 'Gifu' },
    { code: '10', name: 'Gunma' },
    { code: '34', name: 'Hiroshima' },
    { code: '01', name: 'Hokkaido' },
    { code: '28', name: 'Hyōgo' },
    { code: '08', name: 'Ibaraki' },
    { code: '17', name: 'Ishikawa' },
    { code: '03', name: 'Iwate' },
    { code: '37', name: 'Kagawa' },
    { code: '46', name: 'Kagoshima' },
    { code: '14', name: 'Kanagawa' },
    { code: '39', name: 'Kōchi' },
    { code: '43', name: 'Kumamoto' },
    { code: '26', name: 'Kyōto' },
    { code: '24', name: 'Mie' },
    { code: '04', name: 'Miyagi' },
    { code: '45', name: 'Miyazaki' },
    { code: '20', name: 'Nagano' },
    { code: '42', name: 'Nagasaki' },
    { code: '29', name: 'Nara' },
    { code: '15', name: 'Niigata' },
    { code: '44', name: 'Ōita' },
    { code: '33', name: 'Okayama' },
    { code: '47', name: 'Okinawa' },
    { code: '27', name: 'Ōsaka' },
    { code: '41', name: 'Saga' },
    { code: '11', name: 'Saitama' },
    { code: '25', name: 'Shiga' },
    { code: '32', name: 'Shimane' },
    { code: '22', name: 'Shizuoka' },
    { code: '09', name: 'Tochigi' },
    { code: '36', name: 'Tokushima' },
    { code: '13', name: 'Tōkyō' },
    { code: '31', name: 'Tottori' },
    { code: '16', name: 'Toyama' },
    { code: '30', name: 'Wakayama' },
    { code: '06', name: 'Yamagata' },
    { code: '35', name: 'Yamaguchi' },
    { code: '19', name: 'Yamanashi' },
  ],
  KR: [
    { code: '26', name: 'Busan' },
    { code: '27', name: 'Daegu' },
    { code: '30', name: 'Daejeon' },
    { code: '42', name: 'Gangwon' },
    { code: '29', name: 'Gwangju City' },
    { code: '41', name: 'Gyeonggi' },
    { code: '28', name: 'Incheon' },
    { code: '49', name: 'Jeju' },
    { code: '43', name: 'North Chungcheong' },
    { code: '47', name: 'North Gyeongsang' },
    { code: '45', name: 'North Jeolla' },
    { code: '50', name: 'Sejong' },
    { code: '11', name: 'Seoul' },
    { code: '44', name: 'South Chungcheong' },
    { code: '48', name: 'South Gyeongsang' },
    { code: '46', name: 'South Jeolla' },
    { code: '31', name: 'Ulsan' },
  ],
  MX: [
    { code: 'AGU', name: 'Aguascalientes' },
    { code: 'BCN', name: 'Baja California' },
    { code: 'BCS', name: 'Baja California Sur' },
    { code: 'CAM', name: 'Campeche' },
    { code: 'CHP', name: 'Chiapas' },
    { code: 'CHH', name: 'Chihuahua' },
    { code: 'COA', name: 'Coahuila' },
    { code: 'COL', name: 'Colima' },
    { code: 'CMX', name: 'Ciudad de México' },
    { code: 'DUR', name: 'Durango' },
    { code: 'GUA', name: 'Guanajuato' },
    { code: 'GRO', name: 'Guerrero' },
    { code: 'HID', name: 'Hidalgo' },
    { code: 'JAL', name: 'Jalisco' },
    { code: 'MEX', name: 'México' },
    { code: 'MIC', name: 'Michoacán' },
    { code: 'MOR', name: 'Morelos' },
    { code: 'NAY', name: 'Nayarit' },
    { code: 'NLE', name: 'Nuevo León' },
    { code: 'OAX', name: 'Oaxaca' },
    { code: 'PUE', name: 'Puebla' },
    { code: 'QUE', name: 'Querétaro' },
    { code: 'ROO', name: 'Quintana Roo' },
    { code: 'SLP', name: 'San Luis Potosí' },
    { code: 'SIN', name: 'Sinaloa' },
    { code: 'SON', name: 'Sonora' },
    { code: 'TAB', name: 'Tabasco' },
    { code: 'TAM', name: 'Tamaulipas' },
    { code: 'TLA', name: 'Tlaxcala' },
    { code: 'VER', name: 'Veracruz' },
    { code: 'YUC', name: 'Yucatán' },
    { code: 'ZAC', name: 'Zacatecas' },
  ],
  NZ: [
    { code: 'AUK', name: 'Auckland' },
    { code: 'BOP', name: 'Bay of Plenty' },
    { code: 'CAN', name: 'Canterbury' },
    { code: 'GIS', name: 'Gisborne' },
    { code: 'HKB', name: "Hawke's Bay" },
    { code: 'MWT', name: 'Manawatu-Wanganui' },
    { code: 'MBH', name: 'Marlborough' },
    { code: 'NSN', name: 'Nelson' },
    { code: 'NTL', name: 'Northland' },
    { code: 'OTA', name: 'Otago' },
    { code: 'STL', name: 'Southland' },
    { code: 'TKI', name: 'Taranaki' },
    { code: 'TAS', name: 'Tasman' },
    { code: 'WKO', name: 'Waikato' },
    { code: 'WGN', name: 'Wellington' },
    { code: 'WTC', name: 'West Coast' },
  ],
  US: [
    { code: 'AL', name: 'Alabama' },
    { code: 'AK', name: 'Alaska' },
    { code: 'AZ', name: 'Arizona' },
    { code: 'AR', name: 'Arkansas' },
    { code: 'CA', name: 'California' },
    { code: 'CO', name: 'Colorado' },
    { code: 'CT', name: 'Connecticut' },
    { code: 'DE', name: 'Delaware' },
    { code: 'DC', name: 'District of Columbia' },
    { code: 'FL', name: 'Florida' },
    { code: 'GA', name: 'Georgia' },
    { code: 'GU', name: 'Guam' },
    { code: 'HI', name: 'Hawaii' },
    { code: 'ID', name: 'Idaho' },
    { code: 'IL', name: 'Illinois' },
    { code: 'IN', name: 'Indiana' },
    { code: 'IA', name: 'Iowa' },
    { code: 'KS', name: 'Kansas' },
    { code: 'KY', name: 'Kentucky' },
    { code: 'LA', name: 'Louisiana' },
    { code: 'ME', name: 'Maine' },
    { code: 'MD', name: 'Maryland' },
    { code: 'MA', name: 'Massachusetts' },
    { code: 'MI', name: 'Michigan' },
    { code: 'MN', name: 'Minnesota' },
    { code: 'MS', name: 'Mississippi' },
    { code: 'MO', name: 'Missouri' },
    { code: 'MT', name: 'Montana' },
    { code: 'NE', name: 'Nebraska' },
    { code: 'NV', name: 'Nevada' },
    { code: 'NH', name: 'New Hampshire' },
    { code: 'NJ', name: 'New Jersey' },
    { code: 'NM', name: 'New Mexico' },
    { code: 'NY', name: 'New York' },
    { code: 'NC', name: 'North Carolina' },
    { code: 'ND', name: 'North Dakota' },
    { code: 'OH', name: 'Ohio' },
    { code: 'OK', name: 'Oklahoma' },
    { code: 'OR', name: 'Oregon' },
    { code: 'PA', name: 'Pennsylvania' },
    { code: 'PR', name: 'Puerto Rico' },
    { code: 'RI', name: 'Rhode Island' },
    { code: 'SC', name: 'South Carolina' },
    { code: 'SD', name: 'South Dakota' },
    { code: 'TN', name: 'Tennessee' },
    { code: 'TX', name: 'Texas' },
    { code: 'UT', name: 'Utah' },
    { code: 'VT', name: 'Vermont' },
    { code: 'VA', name: 'Virginia' },
    { code: 'WA', name: 'Washington' },
    { code: 'WV', name: 'West Virginia' },
    { code: 'WI', name: 'Wisconsin' },
    { code: 'WY', name: 'Wyoming' },
    { code: 'AA', name: 'Armed Americas' },
    { code: 'AE', name: 'Armed Europe' },
    { code: 'AP', name: 'Armed Pacific' },
  ],
  ZA: [
    { code: 'EC', name: 'Eastern Cape' },
    { code: 'FS', name: 'Free State' },
    { code: 'GP', name: 'Gauteng' },
    { code: 'KZN', name: 'KwaZulu-Natal' },
    { code: 'LP', name: 'Limpopo' },
    { code: 'MP', name: 'Mpumalanga' },
    { code: 'NW', name: 'North West' },
    { code: 'NC', name: 'Northern Cape' },
    { code: 'WC', name: 'Western Cape' },
  ],
};

//Priority will be given to those in PROVINCES_BY_COUNTRY
export const PROVINCES_BY_CITY: Record<
  string,
  Record<string, Province[] | undefined>
> = {
  AE: {
    'Abu Dhabi': [
      { code: 'Abu Dhabi', name: 'Abu Dhabi' },
      { code: 'Abu Dhabi City', name: 'Abu Dhabi City' },
      { code: 'Ajban', name: 'Ajban' },
      { code: 'Al Aahad', name: 'Al Aahad' },
      { code: 'Al Adla', name: 'Al Adla' },
      { code: 'Al Ain', name: 'Al Ain' },
      { code: 'Al Dhafra Air Base AE', name: 'Al Dhafra Air Base AE' },
      { code: 'Al Diyaa', name: 'Al Diyaa' },
      { code: 'Al Falah', name: 'Al Falah' },
      { code: 'Al Ghadeer', name: 'Al Ghadeer' },
      { code: 'Al Hazzan', name: 'Al Hazzan' },
      { code: 'Al Kazna', name: 'Al Kazna' },
      { code: 'Al Khatim', name: 'Al Khatim' },
      { code: 'Al Khubasi', name: 'Al Khubasi' },
      { code: 'Al Madar', name: 'Al Madar' },
      { code: 'Al Markaz Industrial Park', name: 'Al Markaz Industrial Park' },
      { code: 'Al Maryah Island', name: 'Al Maryah Island' },
      { code: 'Al Raha', name: 'Al Raha' },
      { code: 'Al Raha Beach Area', name: 'Al Raha Beach Area' },
      { code: 'Al Raudah', name: 'Al Raudah' },
      { code: 'Al Riyadh City', name: 'Al Riyadh City' },
      { code: 'Al Sila', name: 'Al Sila' },
      { code: 'Asab', name: 'Asab' },
      { code: 'Bada Zayed', name: 'Bada Zayed' },
      { code: 'Baniyas', name: 'Baniyas' },
      { code: 'Baraka', name: 'Baraka' },
      { code: 'Beda Zayed', name: 'Beda Zayed' },
      { code: 'Buhasa', name: 'Buhasa' },
      { code: 'Dahan', name: 'Dahan' },
      { code: 'Das Island', name: 'Das Island' },
      { code: 'Delma Island', name: 'Delma Island' },
      { code: 'Gate City', name: 'Gate City' },
      { code: 'Ghantoot', name: 'Ghantoot' },
      { code: 'Habshan', name: 'Habshan' },
      { code: 'Hameem Workers City', name: 'Hameem Workers City' },
      { code: 'ICAD', name: 'ICAD' },
      { code: 'Industrial Park', name: 'Industrial Park' },
      { code: 'Inside Meena Port', name: 'Inside Meena Port' },
      { code: 'Jabal Al Dana', name: 'Jabal Al Dana' },
      { code: 'Khalifa City', name: 'Khalifa City' },
      { code: 'Khalifa City A', name: 'Khalifa City A' },
      { code: 'Khalifa City B', name: 'Khalifa City B' },
      { code: 'Khalifa Park Area', name: 'Khalifa Park Area' },
      { code: 'Liwa', name: 'Liwa' },
      { code: 'Mafraq', name: 'Mafraq' },
      { code: 'Mina Zayed', name: 'Mina Zayed' },
      { code: 'Mirfa', name: 'Mirfa' },
      { code: 'Mohammed Bin Zayed City', name: 'Mohammed Bin Zayed City' },
      { code: 'Mussafah', name: 'Mussafah' },
      { code: 'Mussafah Shabiya', name: 'Mussafah Shabiya' },
      { code: 'Qasar Al Sarab', name: 'Qasar Al Sarab' },
      { code: 'Reem Island', name: 'Reem Island' },
      { code: 'Ruwais', name: 'Ruwais' },
      { code: 'Saadiyat Island', name: 'Saadiyat Island' },
      { code: 'Shahama', name: 'Shahama' },
      { code: 'Shakbout City', name: 'Shakbout City' },
      { code: 'Shamkha', name: 'Shamkha' },
      { code: 'Shawamekh', name: 'Shawamekh' },
      { code: 'Soowah Island', name: 'Soowah Island' },
      { code: 'Sweihan', name: 'Sweihan' },
      { code: 'Tarif', name: 'Tarif' },
      { code: 'Umm Al Nar', name: 'Umm Al Nar' },
      { code: 'Wathaba', name: 'Wathaba' },
      { code: 'Yas Island East', name: 'Yas Island East' },
      { code: 'Zayed Military City', name: 'Zayed Military City' },
    ],
    Ajman: [
      { code: 'Ajman', name: 'Ajman' },
      { code: 'Ajman City', name: 'Ajman City' },
      { code: 'Al Maiden', name: 'Al Maiden' },
      { code: 'Al Riqqah', name: 'Al Riqqah' },
      { code: 'Al Salamah', name: 'Al Salamah' },
      { code: 'Salam City', name: 'Salam City' },
    ],
    'Al Ain': [
      { code: 'Aflaj', name: 'Aflaj' },
      { code: 'Al Ain Airport', name: 'Al Ain Airport' },
      { code: 'Al Ain City', name: 'Al Ain City' },
      { code: 'Dairy Farm', name: 'Dairy Farm' },
      { code: 'Airport', name: 'Airport' },
      { code: 'Al Andalus Compound', name: 'Al Andalus Compound' },
      { code: 'Al Basra', name: 'Al Basra' },
      { code: 'Al Hili', name: 'Al Hili' },
      { code: 'Al Jahli', name: 'Al Jahli' },
      { code: 'Al Jimi', name: 'Al Jimi' },
      { code: 'Al Khabisi', name: 'Al Khabisi' },
      { code: 'Al Khrair', name: 'Al Khrair' },
      { code: 'Al Kuwaitat', name: 'Al Kuwaitat' },
      { code: 'Al Manaseer', name: 'Al Manaseer' },
      { code: 'Al Masoudi', name: 'Al Masoudi' },
      { code: 'Al Misbah', name: 'Al Misbah' },
      { code: 'Al Mnaizfah', name: 'Al Mnaizfah' },
      { code: 'Al Mnaizlah', name: 'Al Mnaizlah' },
      { code: 'Al Murabba', name: 'Al Murabba' },
      { code: 'Al Mutawaa', name: 'Al Mutawaa' },
      { code: 'Al Muwaji', name: 'Al Muwaji' },
      { code: 'Al Owainah', name: 'Al Owainah' },
      { code: 'Al Qattara', name: 'Al Qattara' },
      { code: 'Al Rumailah', name: 'Al Rumailah' },
      { code: 'Al Ruwaikah', name: 'Al Ruwaikah' },
      { code: 'Al Sanaiya', name: 'Al Sanaiya' },
      { code: 'Al Sarooj', name: 'Al Sarooj' },
      { code: 'Al Sharej', name: 'Al Sharej' },
      { code: 'Al Tawam', name: 'Al Tawam' },
      { code: 'Al Towayya', name: 'Al Towayya' },
      { code: 'Asharej', name: 'Asharej' },
      { code: 'Bawadi Mall', name: 'Bawadi Mall' },
      { code: 'Civic Centre', name: 'Civic Centre' },
      { code: 'Falaj Hazzaa', name: 'Falaj Hazzaa' },
      { code: 'Hili Al Khaleef', name: 'Hili Al Khaleef' },
      { code: 'Hili Oasis', name: 'Hili Oasis' },
      { code: 'Hili Sanaiya', name: 'Hili Sanaiya' },
      { code: 'Main Street Al Ain', name: 'Main Street Al Ain' },
      { code: 'Mreifia', name: 'Mreifia' },
      { code: 'Neima', name: 'Neima' },
      { code: 'New Al Kuwaitat', name: 'New Al Kuwaitat' },
      { code: 'New Al Sarooj', name: 'New Al Sarooj' },
      { code: 'Neyadat', name: 'Neyadat' },
      { code: 'Oud Al Touba', name: 'Oud Al Touba' },
      { code: 'Ramlat Zakher', name: 'Ramlat Zakher' },
      { code: 'Sanaiya', name: 'Sanaiya' },
      { code: 'Shabiya Khalifa', name: 'Shabiya Khalifa' },
      { code: 'Town Centre', name: 'Town Centre' },
      { code: 'Zakher', name: 'Zakher' },
    ],
    Dubai: [
      { code: 'Abu Hail', name: 'Abu Hail' },
      { code: 'Al Aweer', name: 'Al Aweer' },
      { code: 'Al Barari', name: 'Al Barari' },
      { code: 'Al Barsha', name: 'Al Barsha' },
      { code: 'Al Barsha South', name: 'Al Barsha South' },
      { code: 'Al Furjan', name: 'Al Furjan' },
      { code: 'Al Garhoud', name: 'Al Garhoud' },
      { code: 'Al Ghusais', name: 'Al Ghusais' },
      {
        code: 'Al Ghusais Industrial Area',
        name: 'Al Ghusais Industrial Area',
      },
      { code: 'Al Jaddaf', name: 'Al Jaddaf' },
      { code: 'Al Khawaneej', name: 'Al Khawaneej' },
      { code: 'Al Khor', name: 'Al Khor' },
      { code: 'Al Layan', name: 'Al Layan' },
      { code: 'Al Nahda 1', name: 'Al Nahda 1' },
      { code: 'Al Nahda New', name: 'Al Nahda New' },
      { code: 'Al Quoz', name: 'Al Quoz' },
      { code: 'Al Ras', name: 'Al Ras' },
      { code: 'Al Rigga', name: 'Al Rigga' },
      { code: 'Al Sofouh Gardens', name: 'Al Sofouh Gardens' },
      { code: 'Al Thammam', name: 'Al Thammam' },
      { code: 'Al Twar', name: 'Al Twar' },
      { code: 'Al Waha', name: 'Al Waha' },
      { code: 'Al Warqa', name: 'Al Warqa' },
      { code: 'Al Warsan', name: 'Al Warsan' },
      { code: 'Arabian Ranches', name: 'Arabian Ranches' },
      { code: 'Ayal Nasir', name: 'Ayal Nasir' },
      { code: 'Barsha Heights', name: 'Barsha Heights' },
      { code: 'Bur Dubai', name: 'Bur Dubai' },
      { code: 'Business Bay', name: 'Business Bay' },
      { code: 'City of Arabia', name: 'City of Arabia' },
      { code: 'CMC Dubai', name: 'CMC Dubai' },
      { code: 'Damac Hills 1', name: 'Damac Hills 1' },
      { code: 'Deira', name: 'Deira' },
      { code: 'Deira D.O, Dubai', name: 'Deira D.O, Dubai' },
      { code: 'Discovery Gardens', name: 'Discovery Gardens' },
      { code: 'Downtown Dubai', name: 'Downtown Dubai' },
      { code: 'Downtown Jebel Ali', name: 'Downtown Jebel Ali' },
      { code: 'Dubai', name: 'Dubai' },
      { code: 'Dubai Academic City', name: 'Dubai Academic City' },
      { code: 'Dubai Airport Freezone', name: 'Dubai Airport Freezone' },
      { code: 'Dubai Airport T2', name: 'Dubai Airport T2' },
      { code: 'Dubai Commerce City', name: 'Dubai Commerce City' },
      { code: 'Dubai Design District', name: 'Dubai Design District' },
      { code: 'Dubai Health Care City', name: 'Dubai Health Care City' },
      { code: 'Dubai Hills', name: 'Dubai Hills' },
      { code: 'Dubai Humanitarian City', name: 'Dubai Humanitarian City' },
      { code: 'Dubai Industrial City', name: 'Dubai Industrial City' },
      {
        code: 'Dubai International Fin. Ctr',
        name: 'Dubai International Fin. Ctr',
      },
      { code: 'Dubai Internet City', name: 'Dubai Internet City' },
      { code: 'Dubai Investment Park', name: 'Dubai Investment Park' },
      { code: 'Dubai Knowledge Park', name: 'Dubai Knowledge Park' },
      { code: 'Dubai Knowledge Village', name: 'Dubai Knowledge Village' },
      { code: 'Dubai Land', name: 'Dubai Land' },
      { code: 'Dubai Logistic City', name: 'Dubai Logistic City' },
      { code: 'Dubai Marina', name: 'Dubai Marina' },
      { code: 'Dubai Maritime City', name: 'Dubai Maritime City' },
      { code: 'Dubai Media City', name: 'Dubai Media City' },
      { code: 'Dubai Motor City', name: 'Dubai Motor City' },
      { code: 'Dubai Outsource Zone', name: 'Dubai Outsource Zone' },
      { code: 'Dubai Production City', name: 'Dubai Production City' },
      { code: 'Dubai Silicon Oasis', name: 'Dubai Silicon Oasis' },
      { code: 'Dubai South', name: 'Dubai South' },
      { code: 'Dubai Sports City', name: 'Dubai Sports City' },
      { code: 'Dubai Studio City', name: 'Dubai Studio City' },
      { code: 'Dubai Waterfront', name: 'Dubai Waterfront' },
      { code: 'Dubai World Central', name: 'Dubai World Central' },
      { code: 'Emirates Hills', name: 'Emirates Hills' },
      { code: 'Emirates Lakes', name: 'Emirates Lakes' },
      { code: 'Falcon City of Wonders', name: 'Falcon City of Wonders' },
      { code: 'Festival City', name: 'Festival City' },
      { code: 'Frij Al Murar', name: 'Frij Al Murar' },
      { code: 'Gardens', name: 'Gardens' },
      { code: 'Golf City', name: 'Golf City' },
      { code: 'Green Community', name: 'Green Community' },
      { code: 'Hamriya', name: 'Hamriya' },
      { code: 'Hamriya Port', name: 'Hamriya Port' },
      { code: 'Hassyan Power Plant', name: 'Hassyan Power Plant' },
      { code: 'Hatta', name: 'Hatta' },
      { code: 'Hor Al Anz', name: 'Hor Al Anz' },
      { code: 'International City', name: 'International City' },
      {
        code: 'International Media Prod. Zone',
        name: 'International Media Prod. Zone',
      },
      { code: 'Jafliya', name: 'Jafliya' },
      { code: 'Jafza', name: 'Jafza' },
      { code: 'Jan De Nul Waterfront', name: 'Jan De Nul Waterfront' },
      { code: 'Jebel Ali', name: 'Jebel Ali' },
      {
        code: 'Jebel Ali Free Zone',
        name: 'Jebel Ali Free Zone',
      },
      {
        code: 'Jebel Ali Free Zone, North',
        name: 'Jebel Ali Free Zone, North',
      },
      {
        code: 'Jebel Ali Free Zone, South',
        name: 'Jebel Ali Free Zone, South',
      },
      { code: 'Jebel Ali Hills Meraas', name: 'Jebel Ali Hills Meraas' },
      { code: 'Jebel Ali Industrial Area', name: 'Jebel Ali Industrial Area' },
      { code: 'Jumeirah', name: 'Jumeirah' },
      { code: 'Jumeirah Beach Residence', name: 'Jumeirah Beach Residence' },
      { code: 'Jumeirah Golf Estates', name: 'Jumeirah Golf Estates' },
      { code: 'Jumeirah Heights', name: 'Jumeirah Heights' },
      { code: 'Jumeirah Island', name: 'Jumeirah Island' },
      { code: 'Jumeirah Lake Towers', name: 'Jumeirah Lake Towers' },
      { code: 'Jumeirah Village', name: 'Jumeirah Village' },
      { code: 'Jumeirah Village Circle', name: 'Jumeirah Village Circle' },
      { code: 'Jumeirah Village Triangle', name: 'Jumeirah Village Triangle' },
      { code: 'Karama', name: 'Karama' },
      { code: 'Madam', name: 'Madam' },
      { code: 'Mamzar', name: 'Mamzar' },
      { code: 'Mankool', name: 'Mankool' },
      { code: 'Meadows', name: 'Meadows' },
      { code: 'Meena Bazar', name: 'Meena Bazar' },
      { code: 'Meydan', name: 'Meydan' },
      { code: 'Mirdif', name: 'Mirdif' },
      { code: 'Mizher', name: 'Mizher' },
      { code: 'Mudun', name: 'Mudun' },
      { code: 'Muraqqabat', name: 'Muraqqabat' },
      { code: 'Murshid Bazar', name: 'Murshid Bazar' },
      { code: 'Muteena', name: 'Muteena' },
      { code: 'Nad Al Hamar', name: 'Nad Al Hamar' },
      { code: 'Nad Al Sheba Meydan', name: 'Nad Al Sheba Meydan' },
      { code: 'Nadd Al Thamam', name: 'Nadd Al Thamam' },
      { code: 'Oud Muteena', name: 'Oud Muteena' },
      { code: 'Palm Island', name: 'Palm Island' },
      { code: 'Port Saeed', name: 'Port Saeed' },
      { code: 'Q Point Al Mazaya', name: 'Q Point Al Mazaya' },
      { code: 'Ras Al Khor', name: 'Ras Al Khor' },
      { code: 'Rashidiya', name: 'Rashidiya' },
      { code: 'Remraam', name: 'Remraam' },
      { code: 'Rigga Al Buteen', name: 'Rigga Al Buteen' },
      { code: 'Satwa', name: 'Satwa' },
      { code: 'Solar Park', name: 'Solar Park' },
      { code: 'Sonapur', name: 'Sonapur' },
      { code: 'Springs', name: 'Springs' },
      { code: 'Techno Park', name: 'Techno Park' },
      { code: 'Technology Park', name: 'Technology Park' },
      { code: 'The Greens', name: 'The Greens' },
      { code: 'The Lakes', name: 'The Lakes' },
      { code: 'The Meadows', name: 'The Meadows' },
      { code: 'The Springs', name: 'The Springs' },
      { code: 'The Views', name: 'The Views' },
      { code: 'The Villa', name: 'The Villa' },
      { code: 'Umm Ramool', name: 'Umm Ramool' },
    ],
    Fujairah: [
      { code: 'Al Aqaa', name: 'Al Aqaa' },
      { code: 'Al Attain', name: 'Al Attain' },
      { code: 'Al Dhaid', name: 'Al Dhaid' },
      { code: 'Al Hail Industrial Area', name: 'Al Hail Industrial Area' },
      { code: 'Al Hawiyah', name: 'Al Hawiyah' },
      { code: 'Bidya', name: 'Bidya' },
      { code: 'Dibba', name: 'Dibba' },
      { code: 'Faseel', name: 'Faseel' },
      { code: 'Fujairah', name: 'Fujairah' },
      { code: 'Fujairah Airport', name: 'Fujairah Airport' },
      { code: 'Fujairah City', name: 'Fujairah City' },
      { code: 'Fujairah Free Zone', name: 'Fujairah Free Zone' },
      { code: 'Fujairah Port', name: 'Fujairah Port' },
      { code: 'Masafi', name: 'Masafi' },
      { code: 'Murba', name: 'Murba' },
      { code: 'Qidfa', name: 'Qidfa' },
    ],
    'Ras Al Khaimah': [
      { code: 'Al Burairat', name: 'Al Burairat' },
      { code: 'Al Dhait', name: 'Al Dhait' },
      { code: 'Al Dhait North', name: 'Al Dhait North' },
      { code: 'Al Dhait South', name: 'Al Dhait South' },
      { code: 'Al Digdaga', name: 'Al Digdaga' },
      { code: 'Al Ghail Industrial Park', name: 'Al Ghail Industrial Park' },
      { code: 'Al Hudaibah', name: 'Al Hudaibah' },
      { code: 'Al Humrah', name: 'Al Humrah' },
      { code: 'Al Kharran', name: 'Al Kharran' },
      { code: 'Al Khuzam', name: 'Al Khuzam' },
      { code: 'Al Mairid', name: 'Al Mairid' },
      { code: 'Al Mamourah', name: 'Al Mamourah' },
      { code: 'Al Nakeel', name: 'Al Nakeel' },
      { code: 'Al Nakheel', name: 'Al Nakheel' },
      { code: 'Al Quawasim Corniche', name: 'Al Quawasim Corniche' },
      { code: 'Al Rams Road', name: 'Al Rams Road' },
      { code: 'Al Uraibi', name: 'Al Uraibi' },
      { code: 'Jazeerah Al Hamra', name: 'Jazeerah Al Hamra' },
      { code: 'Khor Kheir', name: 'Khor Kheir' },
      { code: 'Khor Khwair', name: 'Khor Khwair' },
      { code: 'Nakheel', name: 'Nakheel' },
      { code: 'Qusaidat', name: 'Qusaidat' },
      { code: 'RAK City', name: 'RAK City' },
      { code: 'RAK Corniche', name: 'RAK Corniche' },
      { code: 'Ras Al Khaimah', name: 'Ras Al Khaimah' },
      { code: 'Ras Al Khaimah Airport', name: 'Ras Al Khaimah Airport' },
      { code: 'Ras Al Khaimah City', name: 'Ras Al Khaimah City' },
      {
        code: 'Ras Al Khaimah Maritime City',
        name: 'Ras Al Khaimah Maritime City',
      },
    ],
    Sharjah: [
      { code: 'Al Abraq', name: 'Al Abraq' },
      { code: 'Al Barashi', name: 'Al Barashi' },
      { code: 'Al Dar Al Baida', name: 'Al Dar Al Baida' },
      { code: 'Al Haditha', name: 'Al Haditha' },
      { code: 'Al Khezamia', name: 'Al Khezamia' },
      { code: 'Al Rahmaniya', name: 'Al Rahmaniya' },
      { code: 'Al Ramlah', name: 'Al Ramlah' },
      { code: 'Al Sajja', name: 'Al Sajja' },
      { code: 'Al Zahra', name: 'Al Zahra' },
      { code: 'Dibba Al Hosn', name: 'Dibba Al Hosn' },
      { code: 'Hamriya Free Zone', name: 'Hamriya Free Zone' },
      { code: 'Industrial Area', name: 'Industrial Area' },
      { code: 'Kalba', name: 'Kalba' },
      { code: 'Khor Fakkan', name: 'Khor Fakkan' },
      { code: 'Sharjah', name: 'Sharjah' },
      { code: 'Sharjah City', name: 'Sharjah City' },
      { code: 'Sharjah Free Zone', name: 'Sharjah Free Zone' },
      {
        code: 'Sharjah International Airport',
        name: 'Sharjah International Airport',
      },
      { code: 'Al Raas', name: 'Al Raas' },
    ],
    'Umm Al Quwain': [{ code: 'Umm Al Quwain', name: 'Umm Al Quwain' }],
  },
  QA: {
    'Al Khor': [
      { code: 'Al Dhakira', name: 'Al Dhakira' },
      { code: 'Al Khor', name: 'Al Khor' },
      { code: 'Al Thakira', name: 'Al Thakira' },
      { code: 'Ras Laffan', name: 'Ras Laffan' },
      {
        code: 'Ras Laffan Industrial City',
        name: 'Ras Laffan Industrial City',
      },
    ],
    'Al Rayyan': [
      { code: 'Al Udeid', name: 'Al Udied' },
      { code: 'Dukhan', name: 'Dukhan' },
      { code: 'Al Nasraniya', name: 'Al Nasraniya' },
      { code: 'Zekreet Interchange', name: 'Zekreet Interchange' },
    ],
    'Al Shamal': [
      { code: 'Abu Dhalouf', name: 'Abu Dhalouf' },
      { code: 'Al Ghariya', name: 'Al Ghariya' },
      { code: 'Al Shamal', name: 'Al Shamal' },
      { code: 'Fuwayrit', name: 'Fuwayrit' },
    ],
    Doha: [
      { code: 'Doha', name: 'Doha' },
      { code: 'Abu Nakla', name: 'Abu Nakla' },
      { code: 'Abu Sidra', name: 'Abu Sidra' },
      { code: 'Al Dafna', name: 'Al Dafna' },
      { code: 'Al Dayeen', name: 'Al Dayeen' },
      { code: 'Al Duhail', name: 'Al Duhail' },
      { code: 'Al Gharafa', name: 'Al Gharafa' },
      { code: 'Al Ghuwayriya', name: 'Al Ghuwayriya' },
      { code: 'Al Hilal', name: 'Al Hilal' },
      { code: 'Al Keesha', name: 'Al Keesha' },
      { code: 'Al Kharatiyat', name: 'Al Kharatiyat' },
      { code: 'Al Khulaifat', name: 'Al Khulaifat' },
      { code: 'Al Khuwayr', name: 'Al Khuwayr' },
      { code: 'Al Luqta', name: 'Al Luqta' },
      { code: 'Al Messila', name: 'Al Messila' },
      { code: 'Al Ruwais', name: 'Al Ruwais' },
      { code: 'Al Saad', name: 'Al Saad' },
      { code: 'Al Sailiya', name: 'Al Sailiya' },
      { code: 'Al Shakama', name: 'Al Shakama' },
      { code: 'Al Thakhira', name: 'Al Thakhira' },
      { code: 'Al Waab', name: 'Al Waab' },
      { code: 'Al Wajba', name: 'Al Wajba' },
      { code: 'Al Wakra', name: 'Al Wakra' },
      { code: 'Almansoura', name: 'Almansoura' },
      { code: 'Azizya', name: 'Azizya' },
      { code: 'Bani Hajer', name: 'Bani Hajer' },
      { code: 'Bin Mahmoud', name: 'Bin Mahmoud' },
      { code: 'Buhamour', name: 'Buhamour' },
      { code: 'Dahl Al Hamam', name: 'Dahl Al Hamam' },
      { code: 'Fareej Al Amir', name: 'Fareej Al Amir' },
      { code: 'Fereej Al Amir', name: 'Fereej Al Amir' },
      { code: 'Fereej Al Kulaib', name: 'Fereej Al Kulaib' },
      { code: 'Fereej Al Nasr', name: 'Fereej Al Nasr' },
      { code: 'Fereej Bin Derham', name: 'Fereej Bin Derham' },
      { code: 'Gharrfa', name: 'Gharrfa' },
      { code: 'Hamad Intl Airport', name: 'Hamad Intl Airport' },
      { code: 'Hazm Al Markhiya', name: 'Hazm Al Markhiya' },
      { code: 'Industrial Area', name: 'Industrial Area' },
      { code: 'Izghawa', name: 'Izghawa' },
      { code: 'Jebailat', name: 'Jebailat' },
      { code: 'Jelaiah', name: 'Jelaiah' },
      { code: 'Jemaliya', name: 'Jemaliya' },
      { code: 'Khulaifath', name: 'Khulaifath' },
      { code: 'Lusail', name: 'Lusail' },
      { code: 'Madhinat Khalifa', name: 'Madhinat Khalifa' },
      { code: 'Mamoura', name: 'Mamoura' },
      { code: 'Manaseer', name: 'Manaseer' },
      { code: 'Markhaya', name: 'Markhaya' },
      { code: 'Mesaieed', name: 'Mesaieed' },
      { code: 'Mesaimeer', name: 'Mesaimeer' },
      { code: 'Messila', name: 'Messila' },
      { code: 'Muaither', name: 'Muaither' },
      { code: 'Musheireb', name: 'Musheireb' },
      { code: 'Najma', name: 'Najma' },
      { code: 'New Al Rayyan', name: 'New Al Rayyan' },
      { code: 'New Industrial Area', name: 'New Industrial Area' },
      { code: 'New Salata', name: 'New Salata' },
      { code: 'Nuaija', name: 'Nuaija' },
      { code: 'Old Airport', name: 'Old Airport' },
      { code: 'Old Al Rayyan', name: 'Old Al Rayyan' },
      { code: 'Onaiza', name: 'Onaiza' },
      { code: 'Pearl Qatar', name: 'Pearl Qatar' },
      { code: 'Ras Abu Abboud', name: 'Ras Abu Abboud' },
      { code: 'Ras Abu Fontas', name: 'Ras Abu Fontas' },
      { code: 'Rawda Al - Khail', name: 'Rawda Al - Khail' },
      { code: 'Rawdat Rasheed', name: 'Rawdat Rasheed' },
      { code: 'Rawdath Al Hammama', name: 'Rawdath Al Hammama' },
      { code: 'Rumaila', name: 'Rumaila' },
      { code: 'Saha', name: 'Saha' },
      { code: 'Shahaniya', name: 'Shahaniya' },
      { code: 'Shamal', name: 'Shamal' },
      { code: 'Sports City', name: 'Sports City' },
      { code: 'Thumama', name: 'Thumama' },
      { code: 'Umm Al Amad', name: 'Umm Al Amad' },
      { code: 'Umm Al Saneem', name: 'Umm Al Saneem' },
      { code: 'Umm Bab', name: 'Umm Bab' },
      { code: 'Umm Garn', name: 'Umm Garn' },
      { code: 'Umm Ghuwailina', name: 'Umm Ghuwailina' },
      { code: 'Umm Lekhba', name: 'Umm Lekhba' },
      { code: 'Umm Said', name: 'Umm Said' },
      { code: 'Umm Slal Ali', name: 'Umm Slal Ali' },
      { code: 'Umm Slal Mohammad', name: 'Umm Slal Mohammad' },
      { code: 'Wadi Al Banat', name: 'Wadi Al Banat' },
      { code: 'Westbay', name: 'Westbay' },
      { code: 'Wukair', name: 'Wukair' },
      { code: 'Fereej Al Ali', name: 'Fereej Al Ali' },
    ],
  },
};
/* eslint-enable id-length */
