export const ADDRESS_FIELD_KEYS = [
  'first_name',
  'last_name',
  'line1',
  'line2',
  'line3',
  'country',
  'city',
  'province',
  'postal_code',
  'phone',
  'identification_number',
] as const;

export type AddressFieldType = (typeof ADDRESS_FIELD_KEYS)[number];

export interface AddressFieldValidationRequirements {
  required: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp;
}

export interface AddressField extends AddressFieldValidationRequirements {
  type: AddressFieldType;
  label: string;
  experimentationLabel?: string; //Replacement of the label (in experimentation)
  labelExtension?: string; //Additional information to be displayed next to the label (in experimentation)
  example?: string;
  autoCompleteField?: string;
  customErrorKey?: string; //Will map to an addressForm.json translation - allows region specific errors for fields with the same type
}

export type AddressFieldErrorType =
  | 'maxLength'
  | 'minLength'
  | 'pattern'
  | 'required';

export interface AddressFieldValidationResponse {
  isValid: boolean;
  errorType?: AddressFieldErrorType;
}

// Being using in validationSchemas
export const DEFAULT_PHONE_REGEX = /^(\+)?(\d*)$/;

// Max length is determined by Arvato / shipping requirements. Should not be changed unless Bis Sys requirements have changed.
export const CRITICAL_MAX_LENGTH = 30;

export const DEFAULT_FIRST_NAME_FIELD: AddressField = {
  type: 'first_name',
  label: 'First Name',
  required: true,
  maxLength: 20,
  pattern: /[a-zA-Z]/,
  autoCompleteField: 'given-name',
};

export const DEFAULT_LEGAL_FIRST_NAME_FIELD: AddressField = {
  type: 'first_name',
  label: 'Legal First Name',
  required: true,
  maxLength: 20,
  pattern: /[a-zA-Z]/,
  autoCompleteField: 'given-name',
};

export const DEFAULT_LAST_NAME_FIELD: AddressField = {
  type: 'last_name',
  label: 'Last Name',
  required: true,
  maxLength: 20,
  pattern: /[a-zA-Z]/,
  autoCompleteField: 'family-name',
};

export const DEFAULT_LEGAL_LAST_NAME_FIELD: AddressField = {
  type: 'last_name',
  label: 'Legal Last Name',
  required: true,
  maxLength: 20,
  pattern: /[a-zA-Z]/,
  autoCompleteField: 'family-name',
};

export const DEFAULT_LINE_1_FIELD: AddressField = {
  type: 'line1',
  label: 'Address',
  required: true,
  maxLength: CRITICAL_MAX_LENGTH,
  autoCompleteField: 'address-line1',
};

export const DEFAULT_LINE_2_FIELD: AddressField = {
  type: 'line2',
  label: 'Apt., suite, etc. (Optional)',
  required: false,
  maxLength: CRITICAL_MAX_LENGTH,
  autoCompleteField: 'address-line2',
};

export const DEFAULT_LINE_3_FIELD: AddressField = {
  type: 'line3',
  label: '',
  required: false,
  maxLength: 12,
  autoCompleteField: 'address-line3',
};

export const DEFAULT_COUNTRY_FIELD: AddressField = {
  type: 'country',
  label: 'Country',
  required: true,
  autoCompleteField: 'country-name',
  maxLength: 3,
};

export const DEFAULT_CITY_FIELD: AddressField = {
  type: 'city',
  label: 'City',
  required: true,
  maxLength: CRITICAL_MAX_LENGTH,
  autoCompleteField: 'address-level2',
};

export const DEFAULT_STATE_FIELD: AddressField = {
  type: 'province',
  label: 'State',
  required: true,
  maxLength: CRITICAL_MAX_LENGTH,
  autoCompleteField: 'address-level1',
};

export const DEFAULT_PROVINCE_FIELD: AddressField = {
  ...DEFAULT_STATE_FIELD,
  label: 'Province',
};

export const DEFAULT_STATE_PROVINCE_REGION_FIELD: AddressField = {
  ...DEFAULT_STATE_FIELD,
  label: 'State/Province/Region',
  required: false,
};

export const DEFAULT_ZIP_CODE_FIELD: AddressField = {
  type: 'postal_code',
  label: 'Zip Code',
  required: true,
  minLength: 3,
  maxLength: 10,
  autoCompleteField: 'postal-code',
};

export const DEFAULT_POSTAL_CODE_FIELD: AddressField = {
  ...DEFAULT_ZIP_CODE_FIELD,
  label: 'Postal Code',
};

export const DEFAULT_PHONE_FIELD: AddressField = {
  type: 'phone',
  label: 'Phone Number',
  required: true,
  minLength: 8,
  maxLength: CRITICAL_MAX_LENGTH,
  pattern: DEFAULT_PHONE_REGEX,
  autoCompleteField: 'tel',
  example: '9876543210',
};

export const DEFAULT_INTL_PHONE_FIELD: AddressField = {
  ...DEFAULT_PHONE_FIELD,
  pattern: DEFAULT_PHONE_REGEX,
  example: '+496912345678',
};
