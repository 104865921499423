import type { BillingRegion, Region } from '../internationalization';

export interface Country {
  name: string;
  alpha2: string;
  alpha3: string;
  region?: Region;
  phoneCountryCode?: string; // Used for phone number
  billingRegion?: BillingRegion; // Should not be used in JF
}

/**
 * All countries we currently ship to.
 * Order of list is currently order the countries are shown in for each region.
 * Any country added here must be added to ADDRESS_REQUIREMENTS_BY_COUNTRY as well
 */

export const SUPPORTED_COUNTRIES: Country[] = [
  // US region
  {
    name: 'United States',
    alpha2: 'US',
    alpha3: 'USA',
    region: 'US',
    billingRegion: 'domestic',
    phoneCountryCode: '1',
  },

  // UAE region
  {
    name: 'United Arab Emirates',
    alpha2: 'AE',
    alpha3: 'ARE',
    region: 'AE',
    billingRegion: 'uae',
    phoneCountryCode: '971',
  },
  {
    name: 'Qatar',
    alpha2: 'QA',
    alpha3: 'QAT',
    region: 'AE',
    billingRegion: 'qa',
    phoneCountryCode: '974',
  },

  // AU region
  {
    name: 'Australia',
    alpha2: 'AU',
    alpha3: 'AUS',
    region: 'AU',
    billingRegion: 'australia',
    phoneCountryCode: '61',
  },

  // Canada region
  {
    name: 'Canada',
    alpha2: 'CA',
    alpha3: 'CAN',
    region: 'CA',
    billingRegion: 'ca',
    phoneCountryCode: '1',
  },

  // EU region
  {
    name: 'Åland Islands',
    alpha2: 'AX',
    alpha3: 'ALA',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '358',
  },
  {
    name: 'Austria',
    alpha2: 'AT',
    alpha3: 'AUT',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '43',
  },
  {
    name: 'Belgium',
    alpha2: 'BE',
    alpha3: 'BEL',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '32',
  },
  {
    name: 'Bulgaria',
    alpha2: 'BG',
    alpha3: 'BGR',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '359',
  },
  {
    name: 'Croatia',
    alpha2: 'HR',
    alpha3: 'HRV',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '385',
  },
  {
    name: 'Cyprus',
    alpha2: 'CY',
    alpha3: 'CYP',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '357',
  },
  {
    name: 'Czech Republic',
    alpha2: 'CZ',
    alpha3: 'CZE',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '420',
  },
  {
    name: 'Denmark',
    alpha2: 'DK',
    alpha3: 'DNK',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '45',
  },
  {
    name: 'Estonia',
    alpha2: 'EE',
    alpha3: 'EST',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '372',
  },
  {
    name: 'Faroe Islands',
    alpha2: 'FO',
    alpha3: 'FRO',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '298',
  },
  {
    name: 'Finland',
    alpha2: 'FI',
    alpha3: 'FIN',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '358',
  },
  {
    name: 'France',
    alpha2: 'FR',
    alpha3: 'FRA',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '33',
  },
  {
    name: 'Germany',
    alpha2: 'DE',
    alpha3: 'DEU',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '49',
  },
  {
    name: 'Greece',
    alpha2: 'GR',
    alpha3: 'GRC',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '30',
  },
  {
    name: 'Greenland',
    alpha2: 'GL',
    alpha3: 'GRL',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '299',
  },
  {
    name: 'Hungary',
    alpha2: 'HU',
    alpha3: 'HUN',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '36',
  },
  {
    name: 'Iceland',
    alpha2: 'IS',
    alpha3: 'ISL',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '354',
  },
  {
    name: 'Ireland',
    alpha2: 'IE',
    alpha3: 'IRL',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '353',
  },
  {
    name: 'Italy',
    alpha2: 'IT',
    alpha3: 'ITA',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '39',
  },
  {
    name: 'Latvia',
    alpha2: 'LV',
    alpha3: 'LVA',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '371',
  },
  {
    name: 'Lithuania',
    alpha2: 'LT',
    alpha3: 'LTU',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '370',
  },
  {
    name: 'Luxembourg',
    alpha2: 'LU',
    alpha3: 'LUX',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '352',
  },
  {
    name: 'Malta',
    alpha2: 'MT',
    alpha3: 'MLT',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '356',
  },
  {
    name: 'Monaco',
    alpha2: 'MC',
    alpha3: 'MCO',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '377',
  },
  {
    name: 'Netherlands',
    alpha2: 'NL',
    alpha3: 'NLD',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '31',
  },
  {
    name: 'Norway',
    alpha2: 'NO',
    alpha3: 'NOR',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '47',
  },
  {
    name: 'Poland',
    alpha2: 'PL',
    alpha3: 'POL',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '48',
  },
  {
    name: 'Portugal',
    alpha2: 'PT',
    alpha3: 'PRT',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '351',
  },
  {
    name: 'Romania',
    alpha2: 'RO',
    alpha3: 'ROU',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '40',
  },
  {
    name: 'Slovakia',
    alpha2: 'SK',
    alpha3: 'SVK',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '421',
  },
  {
    name: 'Slovenia',
    alpha2: 'SI',
    alpha3: 'SVN',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '386',
  },
  {
    name: 'Spain',
    alpha2: 'ES',
    alpha3: 'ESP',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '34',
  },
  {
    name: 'Sweden',
    alpha2: 'SE',
    alpha3: 'SWE',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '46',
  },
  {
    name: 'Switzerland',
    alpha2: 'CH',
    alpha3: 'CHE',
    region: 'EU',
    billingRegion: 'international',
    phoneCountryCode: '41',
  },

  // New Zealand region
  {
    name: 'New Zealand',
    alpha2: 'NZ',
    alpha3: 'NZL',
    region: 'NZ',
    billingRegion: 'nz',
    phoneCountryCode: '64',
  },

  // SA region
  {
    name: 'Saudi Arabia',
    alpha2: 'SA',
    alpha3: 'SAU',
    region: 'SA',
    billingRegion: 'sa',
    phoneCountryCode: '966',
  },

  // UK region
  {
    name: 'United Kingdom',
    alpha2: 'GB',
    alpha3: 'GBR',
    region: 'UK',
    billingRegion: 'uk',
    phoneCountryCode: '44',
  },
  {
    name: 'Guernsey',
    alpha2: 'GG',
    alpha3: 'GGY',
    region: 'UK',
    billingRegion: 'uk',
    phoneCountryCode: '44',
  },
  {
    name: 'Isle of Man',
    alpha2: 'IM',
    alpha3: 'IMN',
    region: 'UK',
    billingRegion: 'uk',
    phoneCountryCode: '44',
  },
  {
    name: 'Jersey',
    alpha2: 'JE',
    alpha3: 'JEY',
    region: 'UK',
    billingRegion: 'uk',
    phoneCountryCode: '44',
  },

  // "Rest of World"
  {
    name: 'Bahrain',
    alpha2: 'BH',
    alpha3: 'BHR',
    region: 'BH',
    billingRegion: 'domestic',
    phoneCountryCode: '973',
  },
  {
    name: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    region: 'HK',
    billingRegion: 'domestic',
    phoneCountryCode: '852',
  },
  {
    name: 'Israel',
    alpha2: 'IL',
    alpha3: 'ISR',
    region: 'IL',
    billingRegion: 'domestic',
    phoneCountryCode: '972',
  },
  {
    name: 'Japan',
    alpha2: 'JP',
    alpha3: 'JPN',
    region: 'JP',
    billingRegion: 'domestic',
    phoneCountryCode: '81',
  },
  {
    name: 'South Korea',
    alpha2: 'KR',
    alpha3: 'KOR',
    region: 'KR',
    billingRegion: 'domestic',
    phoneCountryCode: '82',
  },
  {
    name: 'Kuwait',
    alpha2: 'KW',
    alpha3: 'KWT',
    region: 'KW',
    billingRegion: 'domestic',
    phoneCountryCode: '965',
  },
  {
    name: 'Singapore',
    alpha2: 'SG',
    alpha3: 'SGP',
    region: 'SG',
    billingRegion: 'domestic',
    phoneCountryCode: '65',
  },
  {
    name: 'Taiwan',
    alpha2: 'TW',
    alpha3: 'TWN',
    region: 'TW',
    billingRegion: 'domestic',
    phoneCountryCode: '886',
  },
  {
    name: 'South Africa',
    alpha2: 'ZA',
    alpha3: 'ZAF',
    region: 'ZA',
    billingRegion: 'domestic',
    phoneCountryCode: '27',
  },

  // "More Rest of World"
  {
    name: 'India',
    alpha2: 'IN',
    alpha3: 'IND',
    region: 'IN',
    billingRegion: 'in',
    phoneCountryCode: '91',
  }, //only RoW in JF
  {
    name: 'Mexico',
    alpha2: 'MX',
    alpha3: 'MEX',
    region: 'MX',
    billingRegion: 'domestic',
    phoneCountryCode: '52',
  },
  // South America
  {
    name: 'Brazil',
    alpha2: 'BR',
    alpha3: 'BRA',
    region: 'BR',
    billingRegion: 'domestic',
  },
];

/**
 * All countries in the world.
 * This should never need to be updated
 */
export const ALL_COUNTRIES: Country[] = [
  {
    name: 'United States',
    alpha2: 'US',
    alpha3: 'USA',
    phoneCountryCode: '1',
  },
  {
    name: 'Afghanistan',
    alpha2: 'AF',
    alpha3: 'AFG',
    phoneCountryCode: '93',
  },
  {
    name: 'Åland Islands',
    alpha2: 'AX',
    alpha3: 'ALA',
    phoneCountryCode: '358',
  },
  {
    name: 'Albania',
    alpha2: 'AL',
    alpha3: 'ALB',
    phoneCountryCode: '355',
  },
  {
    name: 'Algeria',
    alpha2: 'DZ',
    alpha3: 'DZA',
    phoneCountryCode: '213',
  },
  {
    name: 'American Samoa',
    alpha2: 'AS',
    alpha3: 'ASM',
    phoneCountryCode: '1',
  },
  {
    name: 'Andorra',
    alpha2: 'AD',
    alpha3: 'AND',
    phoneCountryCode: '376',
  },
  {
    name: 'Angola',
    alpha2: 'AO',
    alpha3: 'AGO',
    phoneCountryCode: '244',
  },
  {
    name: 'Anguilla',
    alpha2: 'AI',
    alpha3: 'AIA',
    phoneCountryCode: '1',
  },
  {
    name: 'Antarctica',
    alpha2: 'AQ',
    alpha3: 'ATA',
    phoneCountryCode: '672',
  },
  {
    name: 'Antigua and Barbuda',
    alpha2: 'AG',
    alpha3: 'ATG',
    phoneCountryCode: '1',
  },
  {
    name: 'Argentina',
    alpha2: 'AR',
    alpha3: 'ARG',
    phoneCountryCode: '54',
  },
  {
    name: 'Armenia',
    alpha2: 'AM',
    alpha3: 'ARM',
    phoneCountryCode: '374',
  },
  {
    name: 'Aruba',
    alpha2: 'AW',
    alpha3: 'ABW',
    phoneCountryCode: '297',
  },
  {
    name: 'Australia',
    alpha2: 'AU',
    alpha3: 'AUS',
    phoneCountryCode: '61',
  },
  {
    name: 'Austria',
    alpha2: 'AT',
    alpha3: 'AUT',
    phoneCountryCode: '43',
  },
  {
    name: 'Azerbaijan',
    alpha2: 'AZ',
    alpha3: 'AZE',
    phoneCountryCode: '994',
  },
  {
    name: 'Bahamas',
    alpha2: 'BS',
    alpha3: 'BHS',
    phoneCountryCode: '1',
  },
  {
    name: 'Bahrain',
    alpha2: 'BH',
    alpha3: 'BHR',
    phoneCountryCode: '973',
  },
  {
    name: 'Bangladesh',
    alpha2: 'BD',
    alpha3: 'BGD',
    phoneCountryCode: '880',
  },
  {
    name: 'Barbados',
    alpha2: 'BB',
    alpha3: 'BRB',
    phoneCountryCode: '1',
  },
  {
    name: 'Belarus',
    alpha2: 'BY',
    alpha3: 'BLR',
    phoneCountryCode: '375',
  },
  {
    name: 'Belgium',
    alpha2: 'BE',
    alpha3: 'BEL',
    phoneCountryCode: '32',
  },
  {
    name: 'Belize',
    alpha2: 'BZ',
    alpha3: 'BLZ',
    phoneCountryCode: '501',
  },
  {
    name: 'Benin',
    alpha2: 'BJ',
    alpha3: 'BEN',
    phoneCountryCode: '229',
  },
  {
    name: 'Bermuda',
    alpha2: 'BM',
    alpha3: 'BMU',
    phoneCountryCode: '1',
  },
  {
    name: 'Bhutan',
    alpha2: 'BT',
    alpha3: 'BTN',
    phoneCountryCode: '975',
  },
  {
    name: 'Bolivia (Plurinational State of)',
    alpha2: 'BO',
    alpha3: 'BOL',
    phoneCountryCode: '591',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha2: 'BQ',
    alpha3: 'BES',
    phoneCountryCode: '599',
  },
  {
    name: 'Bosnia and Herzegovina',
    alpha2: 'BA',
    alpha3: 'BIH',
    phoneCountryCode: '387',
  },
  {
    name: 'Botswana',
    alpha2: 'BW',
    alpha3: 'BWA',
    phoneCountryCode: '267',
  },
  {
    name: 'Bouvet Island',
    alpha2: 'BV',
    alpha3: 'BVT',
    phoneCountryCode: '47',
  },
  {
    name: 'Brazil',
    alpha2: 'BR',
    alpha3: 'BRA',
    phoneCountryCode: '55',
  },
  {
    name: 'British Indian Ocean Territory',
    alpha2: 'IO',
    alpha3: 'IOT',
    phoneCountryCode: '246',
  },
  {
    name: 'Brunei Darussalam',
    alpha2: 'BN',
    alpha3: 'BRN',
    phoneCountryCode: '673',
  },
  {
    name: 'Bulgaria',
    alpha2: 'BG',
    alpha3: 'BGR',
    phoneCountryCode: '359',
  },
  {
    name: 'Burkina Faso',
    alpha2: 'BF',
    alpha3: 'BFA',
    phoneCountryCode: '226',
  },
  {
    name: 'Burundi',
    alpha2: 'BI',
    alpha3: 'BDI',
    phoneCountryCode: '257',
  },
  {
    name: 'Cabo Verde',
    alpha2: 'CV',
    alpha3: 'CPV',
    phoneCountryCode: '238',
  },
  {
    name: 'Cambodia',
    alpha2: 'KH',
    alpha3: 'KHM',
    phoneCountryCode: '855',
  },
  {
    name: 'Cameroon',
    alpha2: 'CM',
    alpha3: 'CMR',
    phoneCountryCode: '237',
  },
  {
    name: 'Canada',
    alpha2: 'CA',
    alpha3: 'CAN',
    phoneCountryCode: '1',
  },
  {
    name: 'Cayman Islands',
    alpha2: 'KY',
    alpha3: 'CYM',
    phoneCountryCode: '1',
  },
  {
    name: 'Central African Republic',
    alpha2: 'CF',
    alpha3: 'CAF',
    phoneCountryCode: '236',
  },
  {
    name: 'Chad',
    alpha2: 'TD',
    alpha3: 'TCD',
    phoneCountryCode: '235',
  },
  {
    name: 'Chile',
    alpha2: 'CL',
    alpha3: 'CHL',
    phoneCountryCode: '56',
  },
  {
    name: 'China',
    alpha2: 'CN',
    alpha3: 'CHN',
    phoneCountryCode: '86',
  },
  {
    name: 'Christmas Island',
    alpha2: 'CX',
    alpha3: 'CXR',
    phoneCountryCode: '61',
  },
  {
    name: 'Cocos (Keeling) Islands',
    alpha2: 'CC',
    alpha3: 'CCK',
    phoneCountryCode: '61',
  },
  {
    name: 'Colombia',
    alpha2: 'CO',
    alpha3: 'COL',
    phoneCountryCode: '57',
  },
  {
    name: 'Comoros',
    alpha2: 'KM',
    alpha3: 'COM',
    phoneCountryCode: '269',
  },
  {
    name: 'Congo',
    alpha2: 'CG',
    alpha3: 'COG',
    phoneCountryCode: '242',
  },
  {
    name: 'Congo (the Democratic Republic of the)',
    alpha2: 'CD',
    alpha3: 'COD',
    phoneCountryCode: '243',
  },
  {
    name: 'Cook Islands',
    alpha2: 'CK',
    alpha3: 'COK',
    phoneCountryCode: '682',
  },
  {
    name: 'Costa Rica',
    alpha2: 'CR',
    alpha3: 'CRI',
    phoneCountryCode: '506',
  },
  {
    name: "Côte d'Ivoire",
    alpha2: 'CI',
    alpha3: 'CIV',
    phoneCountryCode: '225',
  },
  {
    name: 'Croatia',
    alpha2: 'HR',
    alpha3: 'HRV',
    phoneCountryCode: '385',
  },
  {
    name: 'Cuba',
    alpha2: 'CU',
    alpha3: 'CUB',
    phoneCountryCode: '53',
  },
  {
    name: 'Curaçao',
    alpha2: 'CW',
    alpha3: 'CUW',
    phoneCountryCode: '599',
  },
  {
    name: 'Cyprus',
    alpha2: 'CY',
    alpha3: 'CYP',
    phoneCountryCode: '357',
  },
  {
    name: 'Czech Republic',
    alpha2: 'CZ',
    alpha3: 'CZE',
    phoneCountryCode: '420',
  },
  {
    name: 'Denmark',
    alpha2: 'DK',
    alpha3: 'DNK',
    phoneCountryCode: '45',
  },
  {
    name: 'Djibouti',
    alpha2: 'DJ',
    alpha3: 'DJI',
    phoneCountryCode: '253',
  },
  {
    name: 'Dominica',
    alpha2: 'DM',
    alpha3: 'DMA',
    phoneCountryCode: '1767',
  },
  {
    name: 'Dominican Republic',
    alpha2: 'DO',
    alpha3: 'DOM',
    phoneCountryCode: '1',
  },
  {
    name: 'Ecuador',
    alpha2: 'EC',
    alpha3: 'ECU',
    phoneCountryCode: '593',
  },
  {
    name: 'Egypt',
    alpha2: 'EG',
    alpha3: 'EGY',
    phoneCountryCode: '20',
  },
  {
    name: 'El Salvador',
    alpha2: 'SV',
    alpha3: 'SLV',
    phoneCountryCode: '503',
  },
  {
    name: 'Equatorial Guinea',
    alpha2: 'GQ',
    alpha3: 'GNQ',
    phoneCountryCode: '240',
  },
  {
    name: 'Eritrea',
    alpha2: 'ER',
    alpha3: 'ERI',
    phoneCountryCode: '291',
  },
  {
    name: 'Estonia',
    alpha2: 'EE',
    alpha3: 'EST',
    phoneCountryCode: '372',
  },
  {
    name: 'Ethiopia',
    alpha2: 'ET',
    alpha3: 'ETH',
    phoneCountryCode: '251',
  },
  {
    name: 'Falkland Islands [Malvinas]',
    alpha2: 'FK',
    alpha3: 'FLK',
    phoneCountryCode: '500',
  },
  {
    name: 'Faroe Islands',
    alpha2: 'FO',
    alpha3: 'FRO',
    phoneCountryCode: '298',
  },
  {
    name: 'Fiji',
    alpha2: 'FJ',
    alpha3: 'FJI',
    phoneCountryCode: '679',
  },
  {
    name: 'Finland',
    alpha2: 'FI',
    alpha3: 'FIN',
    phoneCountryCode: '358',
  },
  {
    name: 'France',
    alpha2: 'FR',
    alpha3: 'FRA',
    phoneCountryCode: '33',
  },
  {
    name: 'French Guiana',
    alpha2: 'GF',
    alpha3: 'GUF',
    phoneCountryCode: '594',
  },
  {
    name: 'French Polynesia',
    alpha2: 'PF',
    alpha3: 'PYF',
    phoneCountryCode: '689',
  },
  {
    name: 'French Southern Territories',
    alpha2: 'TF',
    alpha3: 'ATF',
    phoneCountryCode: '262',
  },
  {
    name: 'Gabon',
    alpha2: 'GA',
    alpha3: 'GAB',
    phoneCountryCode: '241',
  },
  {
    name: 'Gambia',
    alpha2: 'GM',
    alpha3: 'GMB',
    phoneCountryCode: '220',
  },
  {
    name: 'Georgia',
    alpha2: 'GE',
    alpha3: 'GEO',
    phoneCountryCode: '995',
  },
  {
    name: 'Germany',
    alpha2: 'DE',
    alpha3: 'DEU',
    phoneCountryCode: '49',
  },
  {
    name: 'Ghana',
    alpha2: 'GH',
    alpha3: 'GHA',
    phoneCountryCode: '233',
  },
  {
    name: 'Gibraltar',
    alpha2: 'GI',
    alpha3: 'GIB',
    phoneCountryCode: '350',
  },
  {
    name: 'Greece',
    alpha2: 'GR',
    alpha3: 'GRC',
    phoneCountryCode: '30',
  },
  {
    name: 'Greenland',
    alpha2: 'GL',
    alpha3: 'GRL',
    phoneCountryCode: '299',
  },
  {
    name: 'Grenada',
    alpha2: 'GD',
    alpha3: 'GRD',
    phoneCountryCode: '1473',
  },
  {
    name: 'Guadeloupe',
    alpha2: 'GP',
    alpha3: 'GLP',
    phoneCountryCode: '590',
  },
  {
    name: 'Guam',
    alpha2: 'GU',
    alpha3: 'GUM',
    phoneCountryCode: '1',
  },
  {
    name: 'Guatemala',
    alpha2: 'GT',
    alpha3: 'GTM',
    phoneCountryCode: '502',
  },
  {
    name: 'Guernsey',
    alpha2: 'GG',
    alpha3: 'GGY',
    phoneCountryCode: '44',
  },
  {
    name: 'Guinea',
    alpha2: 'GN',
    alpha3: 'GIN',
    phoneCountryCode: '224',
  },
  {
    name: 'Guinea-Bissau',
    alpha2: 'GW',
    alpha3: 'GNB',
    phoneCountryCode: '245',
  },
  {
    name: 'Guyana',
    alpha2: 'GY',
    alpha3: 'GUY',
    phoneCountryCode: '592',
  },
  {
    name: 'Haiti',
    alpha2: 'HT',
    alpha3: 'HTI',
    phoneCountryCode: '509',
  },
  {
    name: 'Heard Island and McDonald Islands',
    alpha2: 'HM',
    alpha3: 'HMD',
    phoneCountryCode: '672',
  },
  {
    name: 'Holy See',
    alpha2: 'VA',
    alpha3: 'VAT',
    phoneCountryCode: '379',
  },
  {
    name: 'Honduras',
    alpha2: 'HN',
    alpha3: 'HND',
    phoneCountryCode: '504',
  },
  {
    name: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    phoneCountryCode: '852',
  },
  {
    name: 'Hungary',
    alpha2: 'HU',
    alpha3: 'HUN',
    phoneCountryCode: '36',
  },
  {
    name: 'Iceland',
    alpha2: 'IS',
    alpha3: 'ISL',
    phoneCountryCode: '354',
  },
  {
    name: 'India',
    alpha2: 'IN',
    alpha3: 'IND',
    phoneCountryCode: '91',
  },
  {
    name: 'Indonesia',
    alpha2: 'ID',
    alpha3: 'IDN',
    phoneCountryCode: '62',
  },
  {
    name: 'Iran (Islamic Republic of)',
    alpha2: 'IR',
    alpha3: 'IRN',
    phoneCountryCode: '98',
  },
  {
    name: 'Iraq',
    alpha2: 'IQ',
    alpha3: 'IRQ',
    phoneCountryCode: '964',
  },
  {
    name: 'Ireland',
    alpha2: 'IE',
    alpha3: 'IRL',
    phoneCountryCode: '353',
  },
  {
    name: 'Isle of Man',
    alpha2: 'IM',
    alpha3: 'IMN',
    phoneCountryCode: '44',
  },
  {
    name: 'Israel',
    alpha2: 'IL',
    alpha3: 'ISR',
    phoneCountryCode: '972',
  },
  {
    name: 'Italy',
    alpha2: 'IT',
    alpha3: 'ITA',
    phoneCountryCode: '39',
  },
  {
    name: 'Jamaica',
    alpha2: 'JM',
    alpha3: 'JAM',
    phoneCountryCode: '1876',
  },
  {
    name: 'Japan',
    alpha2: 'JP',
    alpha3: 'JPN',
    phoneCountryCode: '81',
  },
  {
    name: 'Jersey',
    alpha2: 'JE',
    alpha3: 'JEY',
    phoneCountryCode: '44',
  },
  {
    name: 'Jordan',
    alpha2: 'JO',
    alpha3: 'JOR',
    phoneCountryCode: '962',
  },
  {
    name: 'Kazakhstan',
    alpha2: 'KZ',
    alpha3: 'KAZ',
    phoneCountryCode: '7',
  },
  {
    name: 'Kenya',
    alpha2: 'KE',
    alpha3: 'KEN',
    phoneCountryCode: '254',
  },
  {
    name: 'Kiribati',
    alpha2: 'KI',
    alpha3: 'KIR',
    phoneCountryCode: '686',
  },
  {
    name: "Korea (the Democratic People's Republic of)",
    alpha2: 'KP',
    alpha3: 'PRK',
    phoneCountryCode: '850',
  },
  {
    name: 'South Korea',
    alpha2: 'KR',
    alpha3: 'KOR',
    phoneCountryCode: '82',
  },
  {
    name: 'Kuwait',
    alpha2: 'KW',
    alpha3: 'KWT',
    phoneCountryCode: '965',
  },
  {
    name: 'Kyrgyzstan',
    alpha2: 'KG',
    alpha3: 'KGZ',
    phoneCountryCode: '996',
  },
  {
    name: "Lao People's Democratic Republic",
    alpha2: 'LA',
    alpha3: 'LAO',
    phoneCountryCode: '856',
  },
  {
    name: 'Latvia',
    alpha2: 'LV',
    alpha3: 'LVA',
    phoneCountryCode: '371',
  },
  {
    name: 'Lebanon',
    alpha2: 'LB',
    alpha3: 'LBN',
    phoneCountryCode: '961',
  },
  {
    name: 'Lesotho',
    alpha2: 'LS',
    alpha3: 'LSO',
    phoneCountryCode: '266',
  },
  {
    name: 'Liberia',
    alpha2: 'LR',
    alpha3: 'LBR',
    phoneCountryCode: '231',
  },
  {
    name: 'Libya',
    alpha2: 'LY',
    alpha3: 'LBY',
    phoneCountryCode: '218',
  },
  {
    name: 'Liechtenstein',
    alpha2: 'LI',
    alpha3: 'LIE',
    phoneCountryCode: '423',
  },
  {
    name: 'Lithuania',
    alpha2: 'LT',
    alpha3: 'LTU',
    phoneCountryCode: '370',
  },
  {
    name: 'Luxembourg',
    alpha2: 'LU',
    alpha3: 'LUX',
    phoneCountryCode: '352',
  },
  {
    name: 'Macao',
    alpha2: 'MO',
    alpha3: 'MAC',
    phoneCountryCode: '853',
  },
  {
    name: 'Macedonia (the former Yugoslav Republic of)',
    alpha2: 'MK',
    alpha3: 'MKD',
    phoneCountryCode: '389',
  },
  {
    name: 'Madagascar',
    alpha2: 'MG',
    alpha3: 'MDG',
    phoneCountryCode: '261',
  },
  {
    name: 'Malawi',
    alpha2: 'MW',
    alpha3: 'MWI',
    phoneCountryCode: '265',
  },
  {
    name: 'Malaysia',
    alpha2: 'MY',
    alpha3: 'MYS',
    phoneCountryCode: '60',
  },
  {
    name: 'Maldives',
    alpha2: 'MV',
    alpha3: 'MDV',
    phoneCountryCode: '960',
  },
  {
    name: 'Mali',
    alpha2: 'ML',
    alpha3: 'MLI',
    phoneCountryCode: '223',
  },
  {
    name: 'Malta',
    alpha2: 'MT',
    alpha3: 'MLT',
    phoneCountryCode: '356',
  },
  {
    name: 'Marshall Islands',
    alpha2: 'MH',
    alpha3: 'MHL',
    phoneCountryCode: '692',
  },
  {
    name: 'Martinique',
    alpha2: 'MQ',
    alpha3: 'MTQ',
    phoneCountryCode: '596',
  },
  {
    name: 'Mauritania',
    alpha2: 'MR',
    alpha3: 'MRT',
    phoneCountryCode: '222',
  },
  {
    name: 'Mauritius',
    alpha2: 'MU',
    alpha3: 'MUS',
    phoneCountryCode: '230',
  },
  {
    name: 'Mayotte',
    alpha2: 'YT',
    alpha3: 'MYT',
    phoneCountryCode: '262',
  },
  {
    name: 'Mexico',
    alpha2: 'MX',
    alpha3: 'MEX',
    phoneCountryCode: '52',
  },
  {
    name: 'Micronesia (Federated States of)',
    alpha2: 'FM',
    alpha3: 'FSM',
    phoneCountryCode: '691',
  },
  {
    name: 'Moldova (the Republic of)',
    alpha2: 'MD',
    alpha3: 'MDA',
    phoneCountryCode: '373',
  },
  {
    name: 'Monaco',
    alpha2: 'MC',
    alpha3: 'MCO',
    phoneCountryCode: '377',
  },
  {
    name: 'Mongolia',
    alpha2: 'MN',
    alpha3: 'MNG',
    phoneCountryCode: '976',
  },
  {
    name: 'Montenegro',
    alpha2: 'ME',
    alpha3: 'MNE',
    phoneCountryCode: '382',
  },
  {
    name: 'Montserrat',
    alpha2: 'MS',
    alpha3: 'MSR',
    phoneCountryCode: '1',
  },
  {
    name: 'Morocco',
    alpha2: 'MA',
    alpha3: 'MAR',
    phoneCountryCode: '212',
  },
  {
    name: 'Mozambique',
    alpha2: 'MZ',
    alpha3: 'MOZ',
    phoneCountryCode: '258',
  },
  {
    name: 'Myanmar',
    alpha2: 'MM',
    alpha3: 'MMR',
    phoneCountryCode: '95',
  },
  {
    name: 'Namibia',
    alpha2: 'NA',
    alpha3: 'NAM',
    phoneCountryCode: '264',
  },
  {
    name: 'Nauru',
    alpha2: 'NR',
    alpha3: 'NRU',
    phoneCountryCode: '674',
  },
  {
    name: 'Nepal',
    alpha2: 'NP',
    alpha3: 'NPL',
    phoneCountryCode: '977',
  },
  {
    name: 'Netherlands',
    alpha2: 'NL',
    alpha3: 'NLD',
    phoneCountryCode: '31',
  },
  {
    name: 'New Caledonia',
    alpha2: 'NC',
    alpha3: 'NCL',
    phoneCountryCode: '687',
  },
  {
    name: 'New Zealand',
    alpha2: 'NZ',
    alpha3: 'NZL',
    phoneCountryCode: '64',
  },
  {
    name: 'Nicaragua',
    alpha2: 'NI',
    alpha3: 'NIC',
    phoneCountryCode: '505',
  },
  {
    name: 'Niger',
    alpha2: 'NE',
    alpha3: 'NER',
    phoneCountryCode: '227',
  },
  {
    name: 'Nigeria',
    alpha2: 'NG',
    alpha3: 'NGA',
    phoneCountryCode: '234',
  },
  {
    name: 'Niue',
    alpha2: 'NU',
    alpha3: 'NIU',
    phoneCountryCode: '683',
  },
  {
    name: 'Norfolk Island',
    alpha2: 'NF',
    alpha3: 'NFK',
    phoneCountryCode: '672',
  },
  {
    name: 'Northern Mariana Islands',
    alpha2: 'MP',
    alpha3: 'MNP',
    phoneCountryCode: '1',
  },
  {
    name: 'Norway',
    alpha2: 'NO',
    alpha3: 'NOR',
    phoneCountryCode: '47',
  },
  {
    name: 'Oman',
    alpha2: 'OM',
    alpha3: 'OMN',
    phoneCountryCode: '968',
  },
  {
    name: 'Pakistan',
    alpha2: 'PK',
    alpha3: 'PAK',
    phoneCountryCode: '92',
  },
  {
    name: 'Palau',
    alpha2: 'PW',
    alpha3: 'PLW',
    phoneCountryCode: '680',
  },
  {
    name: 'Palestine, State of',
    alpha2: 'PS',
    alpha3: 'PSE',
    phoneCountryCode: '970',
  },
  {
    name: 'Panama',
    alpha2: 'PA',
    alpha3: 'PAN',
    phoneCountryCode: '507',
  },
  {
    name: 'Papua New Guinea',
    alpha2: 'PG',
    alpha3: 'PNG',
    phoneCountryCode: '675',
  },
  {
    name: 'Paraguay',
    alpha2: 'PY',
    alpha3: 'PRY',
    phoneCountryCode: '595',
  },
  {
    name: 'Peru',
    alpha2: 'PE',
    alpha3: 'PER',
    phoneCountryCode: '51',
  },
  {
    name: 'Philippines',
    alpha2: 'PH',
    alpha3: 'PHL',
    phoneCountryCode: '63',
  },
  {
    name: 'Pitcairn',
    alpha2: 'PN',
    alpha3: 'PCN',
    phoneCountryCode: '64',
  },
  {
    name: 'Poland',
    alpha2: 'PL',
    alpha3: 'POL',
    phoneCountryCode: '48',
  },
  {
    name: 'Portugal',
    alpha2: 'PT',
    alpha3: 'PRT',
    phoneCountryCode: '351',
  },
  {
    name: 'Puerto Rico',
    alpha2: 'PR',
    alpha3: 'PRI',
    phoneCountryCode: '1',
  },
  {
    name: 'Qatar',
    alpha2: 'QA',
    alpha3: 'QAT',
    phoneCountryCode: '974',
  },
  {
    name: 'Réunion',
    alpha2: 'RE',
    alpha3: 'REU',
    phoneCountryCode: '262',
  },
  {
    name: 'Romania',
    alpha2: 'RO',
    alpha3: 'ROU',
    phoneCountryCode: '40',
  },
  {
    name: 'Russian Federation',
    alpha2: 'RU',
    alpha3: 'RUS',
    phoneCountryCode: '7',
  },
  {
    name: 'Rwanda',
    alpha2: 'RW',
    alpha3: 'RWA',
    phoneCountryCode: '250',
  },
  {
    name: 'Saint Barthélemy',
    alpha2: 'BL',
    alpha3: 'BLM',
    phoneCountryCode: '590',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2: 'SH',
    alpha3: 'SHN',
    phoneCountryCode: '290',
  },
  {
    name: 'Saint Kitts and Nevis',
    alpha2: 'KN',
    alpha3: 'KNA',
    phoneCountryCode: '1869',
  },
  {
    name: 'Saint Lucia',
    alpha2: 'LC',
    alpha3: 'LCA',
    phoneCountryCode: '1758',
  },
  {
    name: 'Saint Martin (French part)',
    alpha2: 'MF',
    alpha3: 'MAF',
    phoneCountryCode: '590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    alpha2: 'PM',
    alpha3: 'SPM',
    phoneCountryCode: '508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    alpha2: 'VC',
    alpha3: 'VCT',
    phoneCountryCode: '1784',
  },
  {
    name: 'Samoa',
    alpha2: 'WS',
    alpha3: 'WSM',
    phoneCountryCode: '685',
  },
  {
    name: 'San Marino',
    alpha2: 'SM',
    alpha3: 'SMR',
    phoneCountryCode: '378',
  },
  {
    name: 'Sao Tome and Principe',
    alpha2: 'ST',
    alpha3: 'STP',
    phoneCountryCode: '239',
  },
  {
    name: 'Saudi Arabia',
    alpha2: 'SA',
    alpha3: 'SAU',
    phoneCountryCode: '966',
  },
  {
    name: 'Senegal',
    alpha2: 'SN',
    alpha3: 'SEN',
    phoneCountryCode: '221',
  },
  {
    name: 'Serbia',
    alpha2: 'RS',
    alpha3: 'SRB',
    phoneCountryCode: '381',
  },
  {
    name: 'Seychelles',
    alpha2: 'SC',
    alpha3: 'SYC',
    phoneCountryCode: '248',
  },
  {
    name: 'Sierra Leone',
    alpha2: 'SL',
    alpha3: 'SLE',
    phoneCountryCode: '232',
  },
  {
    name: 'Singapore',
    alpha2: 'SG',
    alpha3: 'SGP',
    phoneCountryCode: '65',
  },
  {
    name: 'Sint Maarten (Dutch)',
    alpha2: 'SX',
    alpha3: 'SXM',
    phoneCountryCode: '1721',
  },
  {
    name: 'Slovakia',
    alpha2: 'SK',
    alpha3: 'SVK',
    phoneCountryCode: '421',
  },
  {
    name: 'Slovenia',
    alpha2: 'SI',
    alpha3: 'SVN',
    phoneCountryCode: '386',
  },
  {
    name: 'Solomon Islands',
    alpha2: 'SB',
    alpha3: 'SLB',
    phoneCountryCode: '677',
  },
  {
    name: 'Somalia',
    alpha2: 'SO',
    alpha3: 'SOM',
    phoneCountryCode: '252',
  },
  {
    name: 'South Africa',
    alpha2: 'ZA',
    alpha3: 'ZAF',
    phoneCountryCode: '27',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    alpha2: 'GS',
    alpha3: 'SGS',
    phoneCountryCode: '500',
  },
  {
    name: 'South Sudan',
    alpha2: 'SS',
    alpha3: 'SSD',
    phoneCountryCode: '211',
  },
  {
    name: 'Spain',
    alpha2: 'ES',
    alpha3: 'ESP',
    phoneCountryCode: '34',
  },
  {
    name: 'Sri Lanka',
    alpha2: 'LK',
    alpha3: 'LKA',
    phoneCountryCode: '94',
  },
  {
    name: 'Sudan',
    alpha2: 'SD',
    alpha3: 'SDN',
    phoneCountryCode: '249',
  },
  {
    name: 'Suriname',
    alpha2: 'SR',
    alpha3: 'SUR',
    phoneCountryCode: '597',
  },
  {
    name: 'Svalbard and Jan Mayen',
    alpha2: 'SJ',
    alpha3: 'SJM',
    phoneCountryCode: '47',
  },
  {
    name: 'Swaziland',
    alpha2: 'SZ',
    alpha3: 'SWZ',
    phoneCountryCode: '268',
  },
  {
    name: 'Sweden',
    alpha2: 'SE',
    alpha3: 'SWE',
    phoneCountryCode: '46',
  },
  {
    name: 'Switzerland',
    alpha2: 'CH',
    alpha3: 'CHE',
    phoneCountryCode: '41',
  },
  {
    name: 'Syrian Arab Republic',
    alpha2: 'SY',
    alpha3: 'SYR',
    phoneCountryCode: '963',
  },
  {
    name: 'Taiwan',
    alpha2: 'TW',
    alpha3: 'TWN',
    phoneCountryCode: '886',
  },
  {
    name: 'Tajikistan',
    alpha2: 'TJ',
    alpha3: 'TJK',
    phoneCountryCode: '992',
  },
  {
    name: 'Tanzania, United Republic of',
    alpha2: 'TZ',
    alpha3: 'TZA',
    phoneCountryCode: '255',
  },
  {
    name: 'Thailand',
    alpha2: 'TH',
    alpha3: 'THA',
    phoneCountryCode: '66',
  },
  {
    name: 'Timor-Leste',
    alpha2: 'TL',
    alpha3: 'TLS',
    phoneCountryCode: '670',
  },
  {
    name: 'Togo',
    alpha2: 'TG',
    alpha3: 'TGO',
    phoneCountryCode: '228',
  },
  {
    name: 'Tokelau',
    alpha2: 'TK',
    alpha3: 'TKL',
    phoneCountryCode: '690',
  },
  {
    name: 'Tonga',
    alpha2: 'TO',
    alpha3: 'TON',
    phoneCountryCode: '676',
  },
  {
    name: 'Trinidad and Tobago',
    alpha2: 'TT',
    alpha3: 'TTO',
    phoneCountryCode: '1868',
  },
  {
    name: 'Tunisia',
    alpha2: 'TN',
    alpha3: 'TUN',
    phoneCountryCode: '216',
  },
  {
    name: 'Turkey',
    alpha2: 'TR',
    alpha3: 'TUR',
    phoneCountryCode: '90',
  },
  {
    name: 'Turkmenistan',
    alpha2: 'TM',
    alpha3: 'TKM',
    phoneCountryCode: '993',
  },
  {
    name: 'Turks and Caicos Islands',
    alpha2: 'TC',
    alpha3: 'TCA',
    phoneCountryCode: '1',
  },
  {
    name: 'Tuvalu',
    alpha2: 'TV',
    alpha3: 'TUV',
    phoneCountryCode: '688',
  },
  {
    name: 'Uganda',
    alpha2: 'UG',
    alpha3: 'UGA',
    phoneCountryCode: '256',
  },
  {
    name: 'Ukraine',
    alpha2: 'UA',
    alpha3: 'UKR',
    phoneCountryCode: '380',
  },
  {
    name: 'United Arab Emirates',
    alpha2: 'AE',
    alpha3: 'ARE',
    phoneCountryCode: '971',
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    alpha2: 'GB',
    alpha3: 'GBR',
    phoneCountryCode: '44',
  },
  {
    name: 'United States Minor Outlying Islands',
    alpha2: 'UM',
    alpha3: 'UMI',
    phoneCountryCode: '1',
  },
  {
    name: 'Uruguay',
    alpha2: 'UY',
    alpha3: 'URY',
    phoneCountryCode: '598',
  },
  {
    name: 'Uzbekistan',
    alpha2: 'UZ',
    alpha3: 'UZB',
    phoneCountryCode: '998',
  },
  {
    name: 'Vanuatu',
    alpha2: 'VU',
    alpha3: 'VUT',
    phoneCountryCode: '678',
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    alpha2: 'VE',
    alpha3: 'VEN',
    phoneCountryCode: '58',
  },
  {
    name: 'Vietnam',
    alpha2: 'VN',
    alpha3: 'VNM',
    phoneCountryCode: '84',
  },
  {
    name: 'Virgin Islands (British)',
    alpha2: 'VG',
    alpha3: 'VGB',
    phoneCountryCode: '1',
  },
  {
    name: 'Virgin Islands (U.S.)',
    alpha2: 'VI',
    alpha3: 'VIR',
    phoneCountryCode: '1',
  },
  {
    name: 'Wallis and Futuna',
    alpha2: 'WF',
    alpha3: 'WLF',
    phoneCountryCode: '681',
  },
  {
    name: 'Western Sahara',
    alpha2: 'EH',
    alpha3: 'ESH',
    phoneCountryCode: '212',
  },
  {
    name: 'Yemen',
    alpha2: 'YE',
    alpha3: 'YEM',
    phoneCountryCode: '967',
  },
  {
    name: 'Zambia',
    alpha2: 'ZM',
    alpha3: 'ZMB',
    phoneCountryCode: '260',
  },
  {
    name: 'Zimbabwe',
    alpha2: 'ZW',
    alpha3: 'ZWE',
    phoneCountryCode: '263',
  },
];

export const ALL_COUNTRY_CODES = SUPPORTED_COUNTRIES.map(
  (country) => country.alpha2,
);
export const DEFAULT_COUNTRY_CODE = 'US';
export type CountryCode = (typeof SUPPORTED_COUNTRIES)[number]['alpha2'];
